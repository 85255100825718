/* App.css */

body {
  margin: 0;
  padding: 0;
  width: 100vw;
  height: 200vh;
  overflow-x: hidden;
}

@font-face {
  font-family: 'fontee';
  src: url('./font.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

.back {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-image: url('./we.webp');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.sec1 {
  width: 100vw;
  height: 100vh;
  position: relative;
  top: 0;
  left: 0;
  font-family: 'fontee';
  color: white;
  font-size: 400%;
}

.logo {
  width: 28%;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1000;
}

.morcego{
  position: absolute;
  top: -50px;
  width: 30%;
  z-index: 500;
}

.r{
  right: 0;
  transform: scale(-1, 1);
  width: 35%;
}

.sec2 {
  position: relative; /* Adicionado para posicionamento absoluto funcionar */
  z-index: 10;
  width: 100vw;
  height: 100vh;
  background: linear-gradient(330deg, rgba(0,0,0,0) 20%, rgba(94,23,235,1) 100%);
}

.carousel-image {
  width: 40vh;
  height: auto;
  position: absolute;
  transform: translate(-50%, -50%);
  left: 50%;
  top: 50%;
  z-index: 1000;
}

.ticker-image {
  position: absolute;
  width: 18vh; /* Ajuste conforme necessário */
  height: auto;
}

.title {
  font-size: 80px;
  transform: translate(-50%, -50%);
  top: 20%;
  left: 50%;
  position: absolute;
  z-index: 1000;
  text-shadow: 2px 2px 5px black;
}


.molde{
  width: 50vh;
  height: auto;
  position: absolute;
  transform: translate(-50%, -50%);
  left: 50%;
  top: 50%;
  z-index: 1001;
}

.infos{
  position: absolute;
  top: 20px;
  width: 100vw;
  display: flex;
  justify-content: space-between;
  z-index: 1000;
}

.links{
  width: 15vw;
  height: 30vh;
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: space-between;
}

a{
  transition: 0.5s;
  font-size: 40px;
}

a:hover{
  text-shadow: 3px 3px 3px rgba(255, 255, 255, 0.6);
  transform: scale(1.1);
  transition: 0.5s;
}

.buy{
  letter-spacing: 3px;
  font-size: 120px;
  text-decoration: none;

}

.lin{
  width: 15vw;
  height: 12vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.tokenCa {
  position: absolute; /* Mudança de absolute para fixed */
  top: 8%; /* Ajuste de distância do topo */
  left: 50%;
  transform: translateX(-50%); /* Centraliza horizontalmente */
  padding: 7px 20px;
  border-radius: 5px;
  font-size: 24px;
  color: white;
  background-color: #0d9c0a;
  font-family: 'fontee';
  cursor: pointer;
  transition: 0.5s;
  z-index: 2000;
}

.tokenCa:hover {
  transform: translateX(-50%) scale(1.1); /* Centralizado ao escalar */
  transition: 0.5s;
}

.footer{
  position: absolute;
  width: 100vw;
  height: 20vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #551a8b;
  font-family: 'fontee';
  font-size: 50px;
  color: aliceblue;
}